@use "../../scss/variables.scss" as vars;

.languageFlag {
  // border: 1px solid red;
  height: 40px;
  width: 80px;
  display: flex;
  flex-direction: row;

  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 50px;
    width: 90px;
  }
  img {
    border-radius: 10px;
    height: 35px;
    width: 60px;
    @media screen and (max-width: 700px) {
      height: 50px;
      width: 90px;
    }
  }
  .chooseLanguageDiv {
    // border: 1px solid red;
    // height: 80px;
    color: white;
    // background-color: black;
    border-radius: 10px;

    @media screen and (max-width: 700px) {
      width: 90px;
    }
    img {
      border-radius: 10px;
      height: 30px;
      width: 50px;
      @media screen and (max-width: 700px) {
        height: 40px;
        width: 80px;
      }
    }
  }
}
.logoContainer {
  max-height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    max-width: 70%;
    // border: 1px solid red;
    object-fit: cover;
    object-position: center;
  }
  // border: 1px solid red;
}

.flagsContainer {
  height: 40px;
  border-radius: 20px;
  .flag {
    height: 30px;
    border-radius: 10px;
    // border:1px solid red;
    img {
      border-radius: 10px;
      height: 25px;
      width: 75%;
    }
  }
}

.powered {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 11vh;

  img {
    height: 15px;
  }
}
.addButtonGreen {
  background-color: #b2fc02;
  // border: 3px solid white;
  color: black;
  height: 48px;
  border-radius: 20px;
}

.addButtonBlue {
  background-color: #365161;
  // border: 3px solid black;
  height: 48px;
  border-radius: 20px;
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.websitestring {
  text-decoration: underline;
}

.exchangeButtonBlack {
  background-color: black;

  color: white;
  height: 48px;
  border-radius: 20px;
  border: 1px solid white;
  .btniconContainer {
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.exchangeButtonWhite {
  background-color: white;
  border: 1px solid black;
  color: black;
  height: 48px;
  border-radius: 20px;
  // border: 1px solid white;
  .btniconContainer {
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.iconContainer {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
.platformBtn {
  height: 30px;
  border-radius: 20px;
  background-color: vars.$color-dlcGreen;
}
